
@import "~bootstrap/scss/bootstrap";


$redUse : #830000;
$greenApprove: rgb(0, 90, 0);
$orange: rgb(182, 73, 11);
$grayCancle: rgb(82, 82, 82);
$lineGray: rgb(211, 211, 211);
$text-color: #000;

$fontBold: "LINESeedSansTH_He";
$fontRegular: "LINESeedSansTH_Rg";
$fontMedium: "LINESeedSansTH_Bd";
$fontLight: "LINESeedSansTH_Th";

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

@font-face {
    font-family: $fontBold;
    src: local("LINESeedSansTH_He"), url(../public/fonts/LINESeedSansTH_He.ttf) format("truetype");
}

@font-face {
    font-family: $fontMedium;
    src: local("LINESeedSansTH_Bd"), url(../public/fonts/LINESeedSansTH_Bd.ttf) format("truetype");
}
@font-face {
    font-family: $fontRegular;
    src: local("LINESeedSansTH_Rg"), url(../public/fonts/LINESeedSansTH_Rg.ttf) format("truetype");
}
@font-face {
    font-family: $fontLight;
    src: local("LINESeedSansTH_Th"), url(../public/fonts/LINESeedSansTH_Th.ttf) format("truetype");
}


body{
    min-height: 100vh;
}