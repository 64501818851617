@import '../../index.scss';


.similar-pers{


    .similar-card-control{
        border-radius: 16px;
        width: 90%;
        min-height: 130px;
        display: grid;
        grid-template-columns: 20% 60% 20%;
        padding: 14px 16px;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 15px 5px 10px $lineGray;
        margin-bottom: 10px;
        @include media-breakpoint-down(md){
           min-height: 100px;
        }

        .similar-profile-img{
            width: 60px;
            height: 60px;
            object-fit: contain;
            margin-top: 12px;
            border-radius: 50%;

            @include media-breakpoint-down(md){
                width: 40px;
                height: 40px;
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .similar-name-control{
            margin-top: 12px;
        }

        .similar-rank-control{
            display: flex;
            flex-direction: column;
        }

        .similar-rank-header{
            font-size: 13px;
            margin-left: auto;
            margin-right: auto;
            font-family: $fontMedium;
            color: black;
        }

        .similar-rank-text{
            font-size: 30px;
            font-family: $fontMedium;
            color: black;
            @include media-breakpoint-down(md){
                font-size: 23px;
            }
        }
        .similar-rank-percent{
            font-size: 13px;
            margin-left: auto;
            margin-right: auto;
            font-family: $fontRegular;
            color: black;
        }
        .similar-name{
            font-size: 17px;
            font-family: $fontMedium;
            color: black;

            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        .similar-team-bold{
            font-size: 17px;
            font-family: $fontMedium;
            color: black;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        .similar-team-normal{
            font-size: 17px;
            font-family: $fontRegular;
            margin-left: 8px;
            color: black;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
    }
}