@import '../../index.scss';

.login{

    padding-top: 20px;


    .text-header{
        font-size: 18px;
        font-family: $fontMedium;
        font-weight: 700;
        margin-top: 18px;
        color: black;

    }
    .text-desc{
        font-size: 14px;
        font-family: $fontRegular;
        font-weight: 700;
        margin-top: 16px;
        color: black;

    }

    .login-loading-control{
        margin-top: 16px;
    }

    .login-loading-text{
        margin-top: 16px;
        font-size: 13px;
    }

    .login-loading{
        margin-left: auto;
        margin-right: auto;
    }

    .img-cover{
        width: 50%;
        height: auto;
        object-fit: contain;

        @include media-breakpoint-down(md){
            width: 90%;
            height: auto;
            object-fit: contain;
        }
    }


    .button-gmail{
        margin-top: 50px;
        cursor: pointer;
        width: auto;
        height: 51px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }
}