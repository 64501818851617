@import "../../../index.scss";

.admin-rejected{
    background-color: white;
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
   
    .text-header{
        text-align: left;
        font-size: 22px;
        font-family: $fontMedium;
        color: black;
        margin-left: 5%;
        width: 95%;
    }
    .info-control{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
      
        .tab-content{
            height: 100% !important;
        }
        .info-row{
            display: grid;
            grid-template-columns: 10% 30% 30% 30%;
            border-bottom: 1px solid gray;
            padding-top: 8px;
            padding-bottom: 8px;

            .text-desc{
                font-size: 12px;
                font-family: $fontRegular;
                color: black;
                text-align: left;
            }
            .text-small{
                font-size: 9px;
                font-family: $fontRegular;
                color: black;
                text-align: left;
            }
            .text-desc-bold{
                font-size: 12px;
                font-family: $fontMedium;
                color: black;
                text-align: left;
            }

            .detail-control{
                display: flex;
                flex-direction: column;
            }
        }
    }

    .not-found-img{
        width: 80px;
        height: auto;
        object-fit: contain;
        margin-top: 150px;
    }

    .not-found-text{
        font-size: 16px;
        font-family: $fontRegular;
        color: black;
        text-align: center;
        height: 100%;
        padding-top: 30px !important;
    }
}