@import "../../../index.scss";


.admin-approved{
    background-color: white;
    height: 95%;
    width: 100%;
   
    .not-found-img{
        width: 80px;
        height: auto;
        object-fit: contain;
        margin-top: 150px;
    }

    .not-found-text{
        font-size: 16px;
        font-family: $fontRegular;
        color: black;
        text-align: center;
        height: 100%;
        padding-top: 30px !important;
    }
    .text-header{
        text-align: left;
        font-size: 22px;
        font-family: $fontMedium;
        color: black;
        width: 95%;
    }

    .tabs-control{
        margin-top: 15px;
        font-size: 11px !important;
        font-family: $fontMedium;
    }

    .nav-item{
        height: 30px !important;
    }

    .nav-link{
        height: 30px !important;
        font-size: 13px !important;
        font-family: $fontMedium;
    }

    .info-control{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
      
        .tab-content{
            height: 100% !important;
        }
        .info-row{
            display: grid;
            grid-template-columns: 10% 20% 30% 25% 15%;
            border-bottom: 1px solid gray;
            padding-top: 8px;
            padding-bottom: 8px;

            .text-desc{
                font-size: 12px;
                font-family: $fontRegular;
                color: black;
                text-align: left;
                white-space: normal;
                overflow-wrap: break-word;
            }
            .text-small{
                font-size: 9px;
                font-family: $fontRegular;
                color: black;
                text-align: left;
            }
            .text-desc-bold{
                font-size: 12px;
                font-family: $fontMedium;
                color: black;
                text-align: left;
            }

            .detail-control{
                display: flex;
                flex-direction: column;
            }
        }
    }

}