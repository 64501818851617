@import '../../../index.scss';


.profile{
    background-color: $redUse;
    height: 100%;
    margin-bottom: 13%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    .divider-line{
        width: 100%;
        height: 1px;
        background-color: $lineGray;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .profile-lock-leave{
        padding: 4px 16px;
        color: white;
        width: 130px;
        font-family: $fontMedium;
        border-radius: 24px;
        background-color: $redUse;
        font-size: 9px;
    }

    .profile-items-control{
        display: grid;
        grid-template-columns: 33% 33% 33%;

        .profile-item{
            cursor: pointer;
            width: 97%;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 16px;
            margin-left: auto;
            margin-right: auto;
            padding-right: 12px;
            border: 1px solid $lineGray;
            border-radius: 24px;

            .profile-text-title{
                
                color: $redUse;
                font-size: 13px;
                text-align: left;
                line-height: 1em;
                font-weight: 500;
                font-family: $fontMedium;
            }

            .profile-text-hl{
                margin-top: 12px;
                color: black;
                font-size: 16px;
                font-weight: bold;
                font-family: $fontMedium;
            }

            .profile-text-desc{
                color: $grayCancle;
                font-size: 16px;
                font-family: $fontMedium;
            }
        }
    }

    .similar-profile-control{

        margin-top: 6px;
    }

    .similar-profile-img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .profile-content-control{
        padding-left: 2%;
        padding-right: 2%;
        background-color: white;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
    }

    .settings-control{
        width: 100%;
        min-height: 90px;
        padding-left: 2%;
        padding-right: 2%;
        position: relative;

        .settings-img{
            cursor: pointer;
            width: 24px;
            height: auto;
            object-fit: contain;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
            position: absolute;
            top:0;
            right: 0;
            bottom: 0;
            margin-right: 12px;
        }
    }
    .header-profile{
        height: auto;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        display: grid;
        grid-template-columns: 50% 50%;
        padding-top: 24px;

        .profile-img{
            width: 90px;
            height: 90px;
            object-fit: cover;
            border-radius: 50%;
        }

        .name-control{
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            margin-bottom: auto;

            .name-text{
                margin-top: 6px;
                font-size: 18px;
                color: black;
                text-align: left;
                font-family: $fontMedium;
                line-height: 1.2em;

                @include media-breakpoint-down(md){
                    font-size: 15px;
                }
            }

            .position-text{
                margin-top: 6px;
                font-size: 16px;
                color: black;
                text-align: left;
                font-family: $fontRegular;
                margin-bottom: auto;
                @include media-breakpoint-down(md){
                    font-size: 12px;
                }
            
            }
            .position-text-desc{
                margin-left: 4px;
                font-size: 16px;
                color: black;
                text-align: left;
                margin-top: auto;
                margin-bottom: auto;
                font-family: $fontMedium;

                @include media-breakpoint-down(md){
                    font-size: 12px;
                }
            }
        }
    }
    .link-personality{
        cursor: pointer;
        font-size: 11px;
        font-family: $fontMedium;
        text-decoration: underline;
        margin-top: 6px;
        color: $redUse;
    }
    .graph-bg {
        margin-top: 20px;

       

        .personality-text-type{
            margin-left: auto;
            margin-right: auto;
            font-size: 15px;
            font-family: $fontRegular;
            color: black;
        }
        .personality-text-type-desc{
            font-size: 15px;
            font-family: $fontMedium;
            margin-left: 6px;
            text-decoration: underline;
            color: black;
        }

        .quote-graph {
            width: 215px;
            height: auto;
            object-fit: contain;

            @include media-breakpoint-down(sm) {
                width: 280px;
            }
        }
        .graph-control {
            height: 150px;
            margin-top: 10px;
            width: 100%;

            canvas{
                width: 100% !important;
            }
    
            @include media-breakpoint-down(sm) {
                margin-top: 0px !important;
            }
        }

        .ocean-control {
            display: grid;
            grid-template-columns: 100%;
            @include media-breakpoint-down(lg) {
                grid-template-columns: 100%;
            }
            @include media-breakpoint-down(md) {
                grid-template-columns: 100%;
            }
    
            .ocean-border {
                border: 4px solid black;
                margin-bottom: 24px;
                padding: 10px 24px;
                width: 80%;
                min-height: 200px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                border-radius: 16px;
                background-color: white;
                box-sizing: border-box;
                box-shadow: 6px 6px 0px black;
    
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
    
                .ocean-header {
                    font-size: 14px;
                    font-family: $fontRegular;
                    font-weight: bold;
                }
                .ocean-text-trait {
                    font-size: 14px;
                    font-family: $fontRegular;
                    font-weight: bold;
                }
                .ocean-text {
                    font-size: 14px;
                    font-family: $fontRegular;
                }
            }
        }
    }


    .content-profile{
        border-radius: 0px 0px 4px 4px;
        width: 100%;
        padding-left: 4px;
        padding-right: 4px;

        
        .button-admin-dashboard{
            width: 100%;
            height: 42px;
            border-radius: 12px;
            border: none;
            cursor: pointer;
            margin-top: 14px;
            background-color: $greenApprove;
         

            .img-calendar{
                width: 20px;
                height: 20px;
                object-fit: contain;
                margin-top: auto;
                margin-bottom: auto;
              
            }

            .text-request-leave{
                font-size: 14px;
                color: white;
                margin-left: 16px;
                font-family: $fontMedium;
            }
        }
        
        .button-request-leave{
            width: 100%;
            height: 42px;
            border-radius: 12px;
            border: none;
            cursor: pointer;
            margin-top: 14px;
            background-color: $redUse;
         

            .img-calendar{
                width: 20px;
                height: 20px;
                object-fit: contain;
                margin-top: auto;
                margin-bottom: auto;
              
            }

            .text-request-leave{
                font-size: 14px;
                color: white;
                margin-left: 16px;
                font-family: $fontMedium;
            }
        }

      

        .header-section{
            font-size: 18px;
            color: black;
            text-align: left;
            font-family: $fontMedium;
        }

        .header-type-leave-text{
            font-size: 14px;
            margin-top: 16px;
            color: black;
            text-align: left;
            font-family: $fontMedium;
        }

        .who-leave-text{
            font-size: 14px;
            color: black;
            text-align: left;
            font-family: $fontRegular;
        }

        .leave-list-control{
            margin-top: 8px;
        }
      
    }
    
    .cancel-button {
        width: 150px;
        height: 41px;
        margin-bottom: 16px;
        border-radius: 6px;
        font-family: $fontMedium;
        color: white;
        border: none;
        font-size: 13px;
        background-color: $redUse;
    }

    .leave-request {
        margin-bottom: 16px;
        width: 100%;
        border: 1px solid $lineGray;
        border-radius: 6px;
        padding: 16px 8px 16px 6px;

        .next-button{
            cursor: pointer;
            font-size: 15px;
            color: #888;
            margin-left: 16px;
            margin-right: 16px;
            font-family: $fontMedium;
            text-decoration: underline;
        }
    }
    .header-control {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        width: 100%;


        .text-header {
            color: black;
            text-align: left;
            font-size: 18px;
            font-family: $fontMedium;
        }
    }
    .nav-fill .nav-item .nav-link.active{
        color: black !important;
        font-family: $fontMedium;
    }
    .nav-fill .nav-item .nav-link{
        color: $lineGray !important;
        font-family: $fontMedium;
    }
    .nav-tabs .nav-link{
        color: black;
    }
    .nav-tabs .nav-link:hover {
        color: $lineGray;
    }

    .nav-tabs .nav-link:active {
        color: black !important;
    }
    .content-control {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        width: 100%;
        border: none;
        box-shadow: none;
        margin-bottom: 16px;

        
        .info-row-control {
            height: auto;
            border-bottom: solid 1px $lineGray;
        }

        .info-row {
            height: auto;
            display: grid;
            padding-top: 6px;
            padding-bottom: 6px;
            grid-template-columns: 20% 70% 10%;

            &:hover {
                cursor: pointer;
            }

            .icon-rouder{
                background-color: $redUse;
                width: 60px;
                height: 60px;
                border-radius: 16px;
                display: flex;

                .icon-color{
                    font-size: 30px;
                    color: white;
                }
            }

            .info-text-control{
                display: flex;
                flex-direction: column;
                margin-left: 24px;
                margin-top: auto;
                margin-bottom: auto;
            }

            .text-info-leave-header {
                font-size: 14px;
                font-family: $fontMedium;
                color: black;
                text-align: left;
            }

            .text-info-leave {
                font-size: 12px;
                font-family: $fontRegular;
                color: $grayCancle;
                text-align: left;

                @include media-breakpoint-down(sm){
                    font-size: 11px;
                }
            }

            .text-info-to {
                font-size: 12px;
                font-family: $fontRegular;
                color: $grayCancle;
                text-align: left;
                margin-left: 4px;
                margin-right: 4px;
            }

            .text-info-status{
                font-size: 11px;
                font-family: $fontMedium;
                color: $grayCancle;
                text-align: left;
             
            }
            .text-info-green {
                font-family: $fontMedium;
                color: $greenApprove;
            }
            .text-info-orange {
                font-family: $fontMedium;
                color: $orange;
            }

            .text-info-red {
                font-family: $fontMedium;
                color: $redUse;
            }

            .text-info-gray {
                font-family: $fontMedium;
                color: $grayCancle;
            }

            .type-control {
                display: flex;

                .icon-img {
                    margin-right: 16px;
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                }
            }

            .expand-img {
                width: 10px;
                height: 10px;
                object-fit: contain;
            }



        }
    }

    .expand-control {
        width: 100%;
        min-height: 70px;

        .desc-text {
            font-size: 14px;
            text-align: left;
            font-family: $fontMedium;
        }

        .desc-li-header {
            font-size: 13px;
            text-align: left;
            font-family: $fontMedium;
        }

        .desc-li-desc {
            font-size: 13px;
            text-align: left;
            margin-left: 12px;
            font-family: $fontRegular;
        }

        .cancel-button {
            width: 150px;
            height: 41px;
            margin-bottom: 16px;
            border-radius: 6px;
            font-family: $fontMedium;
            color: white;
            border: none;
            font-size: 13px;
            background-color: $redUse;
        }

    }


}