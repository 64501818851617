@import '../../index.scss';



.navigation-main{
    .container-control{
        background-color: white;
        height: 95%;
        padding-left: 10% ;
        padding-right: 10% ;

        @include media-breakpoint-down(md){
            padding-left: 0% ;
            padding-right: 0% ;
        }
    }

    .content-control{
        background-color: white;
        height: 90%;
        width: 100%;
        border-left: 2px solid $lineGray;
        border-right: 2px solid $lineGray;
        box-shadow: 0px 10px 15px $lineGray;
        @include media-breakpoint-down(md){
            border: none;
            box-shadow: none;
        }
    }

    

    .text-header {
        margin-top: 50px;
        font-size: 36px;
        font-family: $fontMedium;
        color: black;
    }

   
    .items-control{
        width: 100%;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background-color: white;
        font-family: $fontMedium;
    }
    .items-control-no-line{
        width: 100%;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background-color: white;
        font-family: $fontMedium;
    }

   

    .tab-icon-img{
        width: 50px;
        height: auto;
        margin-bottom: 4px;
        object-fit: contain;
    }
    .navbar {
        border-bottom: solid 1px #ebebeb;
    }
    .bottom-tab-nav {
        border-top: solid 1px #ebebeb;
        padding: 0px;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 60px;
        left: 0;
        right:0;
    }

    .nav-grid{
        position: fixed;
        bottom: 0;
        width: 100%;
        display: grid;
        background-color: white;
        height: 60px;
        grid-template-columns: 50% 50%;
        left: 0;
        right:0;
        border: 3px solid white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
   
    .bottom-nav-link.active {
        color: #922c88;
    }
    .bottom-tab-label {
        font-size: 10px;
        color: black;
    }

    .bottom-tab-label-active {
        font-size: 10px;
        color: red;
    }
}