@import "../../../index.scss";

.admin-dashboard {

    .container-control{
        background-color: white;
    }

    .content-control{
        height: 100%;
        margin-bottom: 10%;

        @include media-breakpoint-down(md){
            margin-bottom: 15%;
        }
    }

    .text-header {
        margin-top: 16px;
        font-size: 36px;
        font-family: $fontMedium;
        color: black;
    }

    .nav-tabs,
    .nav-link {
        color: white;
        font-family: $fontMedium;
        font-size: 18px;
    }
    .nav-link.active {
        color: black !important;
        background-color: white;
    }
    .items-control{
        width: 100%;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background-color: white;
        font-family: $fontMedium;
        border-right: 1px solid black;
    }
    .nav-tabs .nav-link{
        color: $grayCancle;
    }
    .nav-tabs .nav-link:hover {
        color: $grayCancle;
    }

    .nav-tabs .nav-link:active {
        color: black !important;
    }

    .tab-icon-img{
        width: 50px;
        height: auto;
        margin-bottom: 4px;
        object-fit: contain;
    }
    .navbar {
        border-bottom: solid 1px #ebebeb;
    }
    .bottom-tab-nav {
        border-top: solid 1px #ebebeb;
        padding: 0px;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 60px;
    }

    .nav-grid{
        width: 100%;
        display: grid;
        height: 100%;
        grid-template-columns: 50% 50%;
    }
    .nav-link,
    .bottom-nav-link {
        cursor: pointer;
        color: black;
        padding: 0px;
    }
    .bottom-nav-link.active {
        color: #922c88;
    }
    .bottom-tab-label {
        font-size: 10px;
        color: black;
    }

    .bottom-tab-label-active {
        font-size: 10px;
        color: red;
    }
}
