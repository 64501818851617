@import "../../../index.scss";

.admin-employees{
    background-color: white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
   
    .text-header{
        text-align: left;
        font-size: 22px;
        font-family: $fontMedium;
        color: black;
        width: 100%;
    }

    .info-control{
        display: flex;
        height: auto;
        width: 100%;
        flex-direction: column;
        .card-control{
            border: 1px solid rgb(88, 88, 88);
            border-radius: 8px;
            box-shadow: 2px 5px 3px rgb(88, 88, 88);
            padding: 16px;
            width: 100%;
            display: flex;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 8px;
            margin-bottom: 8px;
            flex-direction: column;

            .grid-control{
                display: grid;
                grid-template-columns: 30% 70%;
            }

            .profile-img{
                width: 80px;
                border-radius: 50%;
                height: auto;
                object-fit: contain;
            }

            .name-text{
                text-align: left;
                font-size: 14px;
                color: black;
                font-family: $fontMedium;
            }
            .desc-text{
                text-align: left;
                font-size: 12px;
                color: black;
                font-family: $fontRegular;
            }

            .position-text{
                text-align: left;
                font-size: 12px;
                color: black;
                font-family: $fontMedium;
            }

            .value-text{
                text-align: left;
                font-size: 11px;
                margin-left: 6px;
                color: black;
                font-family: $fontMedium;
            }

            .approve-button{
                width: 200px;
                height: 41px;
                margin-left: 8px;
                margin-right: 8px;
                font-size: 13px;
                margin-top: 8px;
                color: white;
                font-family: $fontMedium;
                border:none;
                background-color: $greenApprove;
                border-radius: 6px;
            }
            .reject-button{
                width: 200px;
                height: 41px;
                margin-left: 8px;
                margin-right: 8px;
                font-size: 13px;
                margin-top: 8px;
                color: white;
                font-family: $fontMedium;
                border:none;
                background-color: $redUse;
                border-radius: 6px;
            }
        }
    }
}