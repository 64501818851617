@import "../../../index.scss";

.admin-report {
    margin-top: 50px;
    padding-bottom: 100px;

    .admin-report-who-text {
        font-size: 14px;
        font-family: $fontMedium;
        color: black;
        text-align: left;
    }

    .admin-report-who-text-sub {
        font-size: 14px;
        font-family: $fontRegular;
        color: black;
        text-align: left;
    }

    .button-print {
        font-size: 15px;
        font-family: $fontMedium;
        margin-top: 30px;
        width: 250px;
        height: 44px;
        margin-right: auto;
        color: white;
        border: none;
        background-color: $redUse;
        border-radius: 4px;
    }

    .summary-report-control {
        margin-top: 40px;
        margin-bottom: 40px;
        border: 1px solid $lineGray;
        border-radius: 6px;
        padding: 6px 14px;
        text-align: left;
        width: 100%;

        .summary-topic {
            font-family: $fontMedium;
            font-size: 16px;
            margin-top: auto;
            margin-bottom: auto;

            @include media-breakpoint-down(sm){
                font-size: 12px;
            }
        }

        .summary-grid-control {
            display: grid;
            grid-template-columns: 50% 50%;
            @include media-breakpoint-down(sm){
                grid-template-columns: 100%;
            }
            .summary-grid-item {
                padding-right: 14px;
                border-right: 1px solid $lineGray;
            }

            .summary-grid-item-right {
                padding-left: 14px;
                padding-right: 14px;
                @include media-breakpoint-down(sm){
                    padding-left: 0px;
                }
            }
        }

        .grid-display {
            display: grid;
            grid-template-columns: 70% 30%;
            border-bottom: 1px solid $lineGray;
            height: 35px;
            @include media-breakpoint-down(sm){
                height: 45px;
            }
            .summary-grid-normal {
                font-family: $fontRegular;
                font-size: 14px;
                margin-top: auto;
                margin-bottom: auto;
                @include media-breakpoint-down(sm){
                    font-size: 12px;
                }
            }

            .summary-grid-header {
                font-family: $fontMedium;
                font-size: 14px;
                margin-top: auto;
                margin-bottom: auto;
                @include media-breakpoint-down(sm){
                    font-size: 12px;
                }
            }
            .summary-grid-value {
                font-family: $fontMedium;
                font-size: 14px;
                margin-top: auto;
                margin-bottom: auto;
                @include media-breakpoint-down(sm){
                    font-size: 12px;
                }
            }
        }
    }

    .admin-report-grid-control {
        display: grid;
        grid-template-columns: 5% 20% 20% 25% 10% 15%;
        border: 1px solid $lineGray;
        height: 45px;

        .admin-report-grid-header {
            font-size: 14px;
            font-family: $fontMedium;
            color: black;
            @include media-breakpoint-down(sm){
                font-size: 12px;
            }
        }
        .admin-report-grid-desc {
            font-size: 12px;
            font-family: $fontRegular;
            color: black;

            @include media-breakpoint-down(sm){
                font-size: 9px;
            }
        }
    }
    .bg-remain {
        background-color: rgb(255, 190, 190);
    }

    .bg-sick {
        background-color: rgb(180, 180, 180);
    }
}
