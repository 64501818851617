@import '../../index.scss';


.holidays{
    display: flex;
    flex-direction: column;

    .holidays-header-control{
        min-height: 70px;
    }

    .holidays-text{
        font-size: 18px;
        font-family: $fontMedium;
        color: black;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .holidays-item{
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .add-holiday-button{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
        margin-left: auto;
        margin-right: 15px;
    }

    .holidays-list{
        display: grid;
        grid-template-columns: 10% 60% 30%;

        .holidays-title{
            font-size: 14px;
            font-family: $fontMedium;
            color: $grayCancle;
        }

        .holidays-desc{
            font-size: 14px;
            font-family: $fontRegular;
            padding-right: 6px;
            color: black;
        }
       
    
    }

    .divider-line {
        width: 100%;
        height: 1px;
        margin-top: 4px;
        background-color: $lineGray;
    }
}