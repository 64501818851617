@import '../../index.scss';


.reqest-leave{

    width: 100%;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
    min-height: 600px;
    padding: 0px;

    .divider{
        width: 100%;
        height: 1px;
        background-color: black;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .info-control{
        text-align: left;
        display: flex;
        min-height: 48px;
        width: 80%;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-left: auto;
        margin-right: auto;
        border-bottom: solid 1px rgb(63, 63, 63);

        .title-text{
            font-size: 14px;
            font-family: $fontMedium;
            color:black;
        }

        .value-text{
            margin-left: auto;
            font-size: 14px;
            font-family: $fontMedium;
            color:black;
        }
        .error-info-text{
            margin-left: auto;
            font-size: 14px;
            font-family: $fontMedium;
            color: $redUse;
        }
     
        .holidays-control{
            margin-left: auto;
           

            .holidays-text{
                text-align: right;
                font-size: 14px;
                font-family: $fontMedium;
                color: red;
            }
        }
    }
    .error-text{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        font-size: 14px;
        font-family: $fontMedium;
        color: $redUse;
    }

    .button-request-leave{
        width: 80%;
        height: 48px;
        border-radius: 6px;
        margin-left: auto;
        margin-right: auto;
        border: none;
        cursor: pointer;
        margin-top: 25px;
        background-color: $redUse;
        font-size: 14px;
        color: white;
        font-family: $fontMedium;

    }
    
    .button-disabled{
        background-color: #797979;
    }

    .form-select{
        width: auto;
        margin-left: auto;
        font-family: $fontRegular;

    }

    .form-text-area{
        margin-top: 8px;
        font-size: 14px;
        font-family: $fontRegular;
    }

}