@import "../../index.scss";

.dashboard {

    .dashboard-control {
        background-color: white;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        display: flex;
        flex-direction: column;

    
     

        .divider-line {
            width: 100%;
            background-color: $lineGray;
            height: 1px;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .course-control {
            padding-left: 2%;
            padding-right: 2%;
            display: inline-block;
            flex-direction: column;

            @include media-breakpoint-down(md) {
                overflow: hidden;
                -ms-overflow-style: none; /* Internet Explorer 10+ */
                scrollbar-width: none; /* Firefox */
            }

            .filter-course-control {
                display: inline-block;
                width: 100%;
                margin-bottom: 8px;
                margin-top: 8px;
                text-align: left;

                @include media-breakpoint-down(md) {
                    display: inline-block;
                    overflow-x: auto;
                    white-space: nowrap;
                }

                .filter-course-item {
                    margin-bottom: 4px;
                    min-width: 100px;
                    padding: 4px 8px 4px 8px;
                    border: 1px solid $redUse;
                    background-color: white;
                    color: black;
                    font-family: $fontMedium;
                    font-size: 13px;
                    border-radius: 28px;
                    margin-right: 4px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 0px;
                    }
                }
                ::-webkit-scrollbar {
                    display: none;
                }
                .filter-course-active {
                    background-color: $redUse;
                    color: white;
                }
            }

            .course-text-title {
                font-size: 18px;
                text-align: center;
                font-family: $fontMedium;
                margin-bottom: 24px;
                text-decoration: underline;
            }
        }

        .course-rec-control {
           
          
            li{

                @include media-breakpoint-down(md){
                    width: auto !important;
                }
                
            }

            .course-item {
                cursor: pointer;
                display: flex;
                height: 260px;
                text-overflow: ellipsis;
                word-wrap: break-word;
                box-shadow: 3px 5px 9px #888;
                flex-direction: column;
                margin-bottom: 16px;
                border-radius: 8px;
                width: auto;
                margin-left: 4px;
                margin-right: 4px;

                @include media-breakpoint-down(md) {
                    width: 250px;
                   
                }

                .course-img {
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    background-color: black;
                }

                .course-content-control {
                    padding: 8px;
                    display: flex;
                    flex-direction: column;
                }

                .course-view-all-control{

                    margin: auto;
                    display: flex;
                }

                .course-view-all{
                    font-size: 20px;
                    color: $redUse;
                    font-family: $fontMedium;
                    text-decoration: underline;
                }

                .course-view-all-icon{
                    margin-left: 5px;
                    font-size: 24px;
                    color: $redUse;
                }

                .course-name {
                    text-align: left;
                    font-size: 16px;
                    font-family: $fontMedium;
                    color: black;
                    overflow: hidden;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                }

                .course-recom-title {
                    text-align: left;
                    margin-top: 4px;
                    font-size: 13px;
                    font-family: $fontMedium;
                    white-space: pre-wrap;
                    text-overflow: ellipsis;
                    color: black;
                }
                .course-recom-desc {
                    text-align: left;
                    margin-top: 4px;
                    font-size: 13px;
                    margin-left: 6px;
                    font-family: $fontRegular;
                    color: black;
                  
                }

                .course-desc {
                    text-align: left;
                    margin-top: 4px;
                    font-size: 14px;
                    font-family: $fontRegular;
                    color: black;
                    overflow: hidden;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
