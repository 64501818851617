@import "../../../../index.scss";



.employees-detail{
    .react-datepicker-wrapper{
        width: auto !important;
        margin-left: auto;
    }
    .input-container{
        display: flex !important;
        border: none !important;
    }

    .header-text{
        font-size: 18px;
        font-family: $fontMedium;
        color: black;
    }

    .edit-mode{
        border: 2px #888 solid;
        border-radius: 6px;
        padding: 14px 7px 14px 7px;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        margin-top: 8px;
    }
    .section-control{
        padding-top: 4px;
        padding-bottom: 4px;
        display: grid;
        grid-template-columns: 30% 70%;

        .section-header{
            font-size: 16px;
            color: black;
            font-family: $fontMedium;
            margin-top: auto;
            margin-bottom: auto;
        }

        .section-desc{
            font-size: 14px;
            color: black;
            font-family: $fontRegular;
            margin-left: auto;
            margin-right: 24px;
            margin-top: auto;
            margin-bottom: auto;
            text-align: right;
        }

        .section-dropdown{
            text-align: right;
            width: auto;
            min-width: 200px !important;
            margin-left: auto !important;
            font-size: 16px;
            font-family: $fontRegular;
            color: black;
            border: 1px solid gray;
            min-height: 40px;
            border-radius: 6px;
            padding-right: 4px;
        }

        .edit-button{
            margin-left: auto;
            min-width: 100px;
            border: none;
            background-color: $redUse;
            border-radius: 8px;
            color: white;
            font-size: 14px;
            display: flex;
            font-family: $fontMedium;
            padding: 4px 12px 4px 12px;

            .edit-img{
                width: 15px;
                height: auto;
                object-fit: contain;
                margin-right: 14px;
            }
        }
    }

    .save-button{
        border: none;
        background-color: $redUse;
        border-radius: 8px;
        color: white;
        font-size: 14px;
        margin-left: 4px;
        margin-right: 4px;
        font-family: $fontMedium;
        padding: 4px 12px 4px 12px;
    }

    .cancel-button{
        margin-left: 4px;
        margin-right: 4px;
        border: none;
        background-color: #888;
        border-radius: 8px;
        color: white;
        font-size: 14px;
        font-family: $fontMedium;
        padding: 4px 12px 4px 12px;
    }
    .profile-control{
        padding: 14px 4px 14px 4px;
        border-bottom: 1px solid black;
        margin-bottom: 24px;

        
    }

    .divider-line{
        width: 100%;
        height: 1px;
        background-color: black;
        margin-top: 4px;
        margin-bottom: 4px;

    }

    .sub-header-text{
        font-size: 16px;
        font-family: $fontMedium;
        color: black;
    }

    .button-full-report{
        background-color: $redUse;
        border-radius: 8px;
        border: none;
        color: white;
        font-size: 16px;
        margin-top: 12px;
        padding: 6px 0px;
        font-family: $fontMedium;
    }

    .grid-display{
        display: grid;
        grid-template-columns: 70% 30%;
    }

    .desc-header-text{
        font-size: 14px;
        color: black;
        font-family: $fontRegular;
        margin-right: auto;
    }

    .desc-text{
        font-size: 14px;
        color: black;
        font-family: $fontRegular;
        margin-left: auto;
        margin-right: 24px;
    }
    
}

