@import '../../index.scss';


.confirm-mbti{

    .confirm-mbti-grid-button{
        display: grid;
        grid-template-columns: 50% 50%;
        margin-top: 24px;

        @include media-breakpoint-down(md){

        }
    }

    .confirm-mbti-message{
        font-size: 16px;
        font-family: $fontMedium;
        color: black;
    }

    .confirm-mbti-confirm{
        background-color: $redUse;
        width: 200px;
        height: 48px;
        border-radius: 8px;
        padding: 4px 8px;
        color: white;
        font-size: 15px;
        font-family: $fontMedium;
        @include media-breakpoint-down(md){
            width: 150px;
        }
    }

    .confirm-mbti-cancel{
        background-color: $grayCancle;
        width: 200px;
        height: 48px;
        border-radius: 8px;
        padding: 4px 8px;
        color: white;
        font-size: 15px;
        font-family: $fontMedium;
        @include media-breakpoint-down(md){
            width: 150px;
        }
    }
}