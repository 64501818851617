@import "../../../index.scss";

.admin-leave {
    padding-top: 16px;
    .title-control {
        display: flex;

        .title-management {
            font-size: 18px;
            font-family: $fontMedium;
            color: black;
        }

        .holidays-button {
            background-color: $redUse;
            border: none;
            border-radius: 12px;
            color: white;
            height: 24px;
            min-width: 100px;
            padding-left: 12px;
            padding-right: 12px;
            font-family: $fontMedium;
            font-size: 14px;
            margin-left: auto;

            @include media-breakpoint-down(sm) {
                margin-left: auto;
                margin-right: auto;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    .divider-line {
        width: 100%;
        height: 1px;
        margin-top: 4px;
        background-color: $grayCancle;
    }

    .header-control {
        margin-top: 8px;
        display: flex;

        .header-button {
            background-color: white;
            border: 1px solid black;
            border-radius: 12px;
            height: 24px;
            min-width: 100px;
            padding-left: 12px;
            padding-right: 12px;
            font-family: $fontMedium;
            font-size: 14px;
            margin-left: 4px;
            margin-right: 4px;
        }

        .header-active {
            background-color: black;
            color: white;
        }
    }
}
