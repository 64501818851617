@import "../../../index.scss";

.admin-search-main{

    width: 100vw;
    min-height: 100vh;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 24px;


    .admin-search-header{
        font-size: 24px;
        color: $text-color;
        font-family: $fontMedium;
    }

    .admin-search-loading-control{
        min-height: 400px;
        display: flex;
        width: 100%;

        .admin-search-loading{
            margin: auto;
            font-size: 16px;
            font-family: $fontMedium;
        }
    }

    .admin-search-result-control{

        margin-top: 48px;
        border: 1px solid #888888;
        border-radius: 8px;
        padding: 14px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        .admin-search-table-control{
            width: 100%;
        }
    }

    .admin-search-input-control{
        display: flex;
        flex-direction: column;
        width: 60%;
        margin-left: auto;
        margin-right: auto;

        .admin-search-input-item{

            display: flex;
            flex-direction: column;

            .admin-search-input-title{
                font-size: 16px;
                color: $text-color;
                font-family: $fontMedium;
                margin-bottom: 8px;
                text-align: left;
            }
            .admin-search-input-form{
                border-radius: 8px;
                width: 100%;
                font-size: 15px;
                font-family: $fontRegular;
            }
        }

        .admin-search-button{
            margin-top: 16px;
            width: 150px;
            height: 51px;
            background-color: $redUse;
            color: white;
            font-size: 16px;
            margin-right: auto;
            border: none;
            border-radius: 8px;
        }
        
    }
}