@import '../../../index.scss';


.mbti-detail{


    .ocean-control{
        margin-bottom: 14px;
        border-bottom: 1px solid $lineGray;
        padding-bottom: 8px;
    }

    .ocean-header{
        font-size: 15px;
        font-family: $fontMedium;
        color: black;
    }

    .ocean-text-trait{
        font-size: 14px;
        font-family: $fontMedium;
        color: black;
    }

    .ocean-text{
        font-size: 12px;
        font-family: $fontRegular;
        color: black;
        line-height: 1.5em;
    }

}