@import '../../../index.scss';


.announcement{

    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 80%;


    .announcement-text{
        color: white;
        font-size: 16px;
        font-family: $fontMedium;
    }
    .announcement-detail-header{
        color: white;
        font-size: 14px;
        font-family: $fontMedium;
        margin-top: auto;
        margin-bottom: auto;
    }
    .announcement-detail{
        color: white;
        font-size: 14px;
        font-family: $fontRegular;
        margin-top: auto;
        margin-bottom: auto;
    }
}